
$margin_all: (
	1:1px,
	xl:96px,
	lg:64px,
	md:48px,
	sm:24px,
	xs:.8em
);
$margin_tab: (
	xl:64px,
	lg:48px,
	md:32px,
	sm:16px,
	xs:.8em
);
$margin_sp: (
	xl:48px,
	lg:32px,
	md:24px,
	sm:16px,
	xs:.6em
);
.m{
	@if $type == "all"{
		@each $name, $size in $margin_all {
			&-#{$name}{
				margin-top: $size;
				margin-bottom: $size;
			}
			&t-#{$name}{
				margin-top: $size;
			}
			&b-#{$name}{
				margin-bottom: $size;
			}
		}
	}
	@if $type == "tab"{
		@each $name, $size in $margin_tab {
			&-#{$name}{
				margin-top: $size;
				margin-bottom: $size;
			}
			&t-#{$name}{
				margin-top: $size;
			}
			&b-#{$name}{
				margin-bottom: $size;
			}
		}
	}
	@if $type == "sp"{
		@each $name, $size in $margin_sp {
			&-#{$name}{
				margin-top: $size;
				margin-bottom: $size;
			}
			&t-#{$name}{
				margin-top: $size;
			}
			&b-#{$name}{
				margin-bottom: $size;
			}
		}
	}
}
.p{
	@if $type == "all"{
		@each $name, $size in $margin_all {
			&-#{$name}{
				padding: $size;
			}
			&t-#{$name}{
				padding-top: $size;
			}
			&b-#{$name}{
				padding-bottom: $size;
			}
			&l-#{$name}{
				padding-left: $size;
			}
			&r-#{$name}{
				padding-right: $size;
			}
		}
	}
	@if $type == "tab"{
		@each $name, $size in $margin_tab {
			&-#{$name}{
				padding: $size;
			}
			&t-#{$name}{
				padding-top: $size;
			}
			&b-#{$name}{
				padding-bottom: $size;
			}
			&l-#{$name}{
				padding-left: $size;
			}
			&r-#{$name}{
				padding-right: $size;
			}
		}
	}
	@if $type == "sp"{
		@each $name, $size in $margin_sp {
			&-#{$name}{
				padding: $size;
			}
			&t-#{$name}{
				padding-top: $size;
			}
			&b-#{$name}{
				padding-bottom: $size;
			}
			&l-#{$name}{
				padding-left: $size;
			}
			&r-#{$name}{
				padding-right: $size;
			}
		}
	}
}
