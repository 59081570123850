@font-face {
  font-family: 'webicon';
  src:
    url('../fonts/webicon.ttf?ygv6f9') format('truetype'),
    url('../fonts/webicon.woff?ygv6f9') format('woff'),
    url('../fonts/webicon.svg?ygv6f9#webicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'webicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e601";
}
.icon-menu:before {
  content: "\e602";
}
.icon-phone:before {
  content: "\e606";
}
.icon-email:before {
  content: "\f003";
}
.icon-clock-o:before {
  content: "\f017";
}
.icon-map-marker:before {
  content: "\f041";
}
.icon-arrow-down:before {
  content: "\e313";
}
.icon-arrow-left:before {
  content: "\e314";
}
.icon-arrow-right:before {
  content: "\e315";
}
.icon-arrow-up:before {
  content: "\e316";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-blank:before {
  content: "\ea7e";
}
.icon-facebook:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-pdf:before {
  content: "\eadf";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-image:before {
  content: "\e90d";
}
