

.tbl-row{
	@if $type == "all"{
		width: 100%;
		th{
			background-color: #f0f0f0;
		}
		th,td{
			padding: .5em 1em;
			border-bottom: 1px solid $color_line;
		}
		tr{
			&:last-child{
				th,td{
					border-bottom: none;
				}
			}
		}
	}
}

.tbl-col{
	@if $type == "all"{
		width: 100%;
		thead{
		}
		th{
			border-bottom: 1px solid $color_line;
			text-align: left;
		}
		th,td{
			padding: .5em;
		}
	}
}

