
.event-list{
	@if $type == "all"{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		&__item{
			display: flex;
			align-items: center;
			width: calc(50% - 30px);
			margin: 15px;
			padding: 15px 0;
			border-top: 2px dotted $color_line;
			border-bottom: 2px dotted $color_line;
			.image{
				width: 40%;
				padding-right: 20px;
			}
			.body{
				.date{
					color: #999;
				}
				.title{}
			}
		}
	}
	@if $type == "sp"{
		margin: 0 -10px;
		&__item{
			width: calc(50% - 20px);
			margin: 10px;
			padding: 10px 0;
			font-size: 1.3rem;
			.image{
				width: 45%;
				padding-right: 10px;
			}
		}
	}
}

.blog-list{
	@if $type == "all"{
		&__item{
			display: flex;
			margin: 16px 0;
			.image{
				position: relative;
				width: 150px;
				height: 150px;
				overflow: hidden;
				flex-shrink: 0;
				background-color: #f0f0f0;
				img{
					width: 100%;
					height: 100%;
					object-fit: cover;
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
				}
			}
			.body{
				padding-left: 16px;
				.date{}
				.title{
					font-size: 1.8rem;
				}
				p{}
			}
		}
	}
}