$start: 2;
$end: 20;
$step: 1;
.w{
	@for $i from $start through $end {
		&#{$i * $step}em{
			width: #{$i * $step}em !important;
		}
	}
}
$start: 1;
$end: 20;
$step: 5;
.w{
	@for $i from $start through $end {
		&#{$i * $step}p{
			width: percentage($i * $step / 100) !important;

		}
	}
	&a{
		width: auto;
	}
}
