

.single-meta{
	@if $type == "all"{
		margin: 8px 0 0;
		text-align: right;
		font-size: 1.2rem;
		color: #999;
		> li{
			display: inline-block;
			margin-right: 1em;
			a{
				color: #999;
			}
		}
	}
}
.single-body{
	@if $type == "all"{
		margin: 40px 0;
	}
}

.pager{
	@if $type == "all"{
		margin: 40px 0 0;
		text-align: center;
		line-height: 1.2;
		a,span{
			display: inline-block;
			padding: .4em .8em .3em;
			border: 1px solid;
			border-color: #ccc;
			background-color: #f9f9f9;
			color: #333;
		}
		span.current{
			border-color: darken($color_main, 10%);
			background-color: $color_main;
			color: #fff;
		}
	}
}
.single-pager{
	@if $type == "all"{
		@include clearfix();
		margin: 40px 0 0;
		line-height: 1.2;
		li{
			width: 48%;
			@extend %oneline;
			a{
				display: inline-block;
				padding: .4em .8em .3em;
				color: #333;
				border: 1px solid;
				border-color: #ccc;
				background-color: #f9f9f9;
			}
		}
		.next{
			float: left;
		}
		.prev{
			float: right;
			text-align: right;
		}
	}
}

.tiny-mce{
	@if $type == "all"{
		@include clearfix();
		h3{
			clear: both;
		}
		> * + h3{
			padding-top: 40px;
		}
		strong{
			font-weight: bold;
		}
		p{
			margin: 1em 0;
			line-height: 1.8;
		}
		ul,ol{
			margin: 1em 0;
			padding: 0 0 0 1.8em;
			line-height: 1.2;
			li{
				padding: 0 0 .5em 0;
			}
		}
		ul{
			list-style: disc;
		}
		ol{
			list-style: decimal;
		}
		img{
			&.alignleft{
				float: left;
				padding: 0 2% 10px 0;
				// width: 48%;
				// height: auto;
			}
			&.alignright{
				float: right;
				padding: 0 0 10px 2%;
				// width: 48%;
				// height: auto;
			}
			&.alignnone{
				padding: 0 2% 10px 0;
				// width: 48%;
				// height: auto;
			}
			&.aligncenter{
				width: 100%;
				height: auto;
				margin: 16px auto;
			}
		}
		table{
			th,td{
				padding: 8px;
				border: 1px solid #aaa;
			}
			thead{
				background-color: #e6e6e6;
				td{
					border-bottom-width: 3px;
				}
			}
		}
	}
	@if $type == "tab"{
		img{
			max-width: 100%;
			height: auto;
		}
	}
	@if $type == "sp"{
		img{
			width: 48%;
			height: auto;
			&.alignnone{
			}
			&.alignleft{
				float: left;
				padding: 0 2% 10px 0;
			}
			&.alignright{
				float: right;
				padding: 0 0 10px 2%;
			}
			&.alignnone{
			}
			&.aligncenter{
				width: 100%;
				margin: 16px auto;
			}
		}
		.leftbox{
			padding: 0 0 10px;
			width: 100%;
			float: none;
		}
		.rightbox{
			padding: 0;
			width: 100%;
			float: none;
		}
	}
}
