
// ベースカラー
$color_bg: #fff;

// テキストカラー
$color_font: #1b1b1b;

// メインカラー
$color_main: $color_font;

// サブカラー（ベースカラー）
$color_sub: #747577;

// アクセントカラー
$color_accent: #ff0000;

// ラインカラー
$color_line: #707070;
