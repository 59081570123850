body{
	@if $type == "all"{
		overflow: hidden;
		background-color: #d3d3d3;
		font-weight: 500;
	}
}
.l-inner{
	@if $type == "all"{
		@include clearfix();
		margin: 0 auto;
		width: $inner_width;
	}
	@if $type == "tab"{
		width: auto;
		padding: 0 $padding_tab;
	}
	@if $type == "sp"{
		width: auto;
		padding: 0 $padding_sp;
	}
}
.page{
	@if $type == "all"{
		@extend .l-inner;
		position: relative;
		padding: 0 30px;
		background-color: #fff;
	}
	@if $type == "sp"{
		min-width: 320px;
		padding-top: $sp_header_height;
	}
}

// ----------------------------------------------------
// inner
// ----------------------------------------------------

// ----------------------------------------------------
// header
// ----------------------------------------------------
.page-header{
	@if $type == "all"{
		display: flex;
		justify-content: space-between;
		align-items: flex-end;
		padding: 24px 0;
	}
	@if $type == "sp"{
		position: fixed;
		right: 0;
		top: 0;
		z-index: 200;
		width: 100%;
		align-items: center;
		padding: 0 0 0 16px;
		height: $sp_header_height;
		background-color: #fff;
		box-shadow: 0 2px 0 0 rgba(#000, .1);
	}
}
.logo-main{
	@if $type == "all"{
	}
	@if $type == "sp"{
		text-align: center;
		img{
			width: 64px;
		}
	}
}
.btn-sp-menu{
	@if $type == "all"{
		display: none;
	}
	@if $type == "sp"{
		display: block;
		cursor: pointer;
		padding: 4px 16px 0;
		i{
			font-size: 3rem;
		}
		&.is-open{
			i:before{
				content: '\e601';
			}
		}
	}
}

.upper-nav{
	@if $type == "all"{
		position: absolute;
		right: 30px;
		top: 64px;
		&__list{
			display: flex;
			> li{
				font-size: 1.2rem;
				&:not(:last-child){
					&:after{
						content: '／';
						display: inline-block;
						margin: 0 16px;
					}
				}
				a{
					display: inline-block;
					padding: 0 0 4px;
					border-bottom: 1px solid transparent;
					text-decoration: none;
					&:hover{
						border-bottom-color: $color_accent;
					}
				}
			}
		}
	}
	@if $type == "sp"{
		position: static;
		&__list{
			display: block;
			transition: all .8s;
			transform: translateX(40px);
			padding: 16px 16px 0;
			> li{
				font-size: 1.4rem;
				&:not(:last-child){
					&:after{
						content: none;
					}
				}
				a{
					display: inline-block;
					padding: 0;
				}
			}
		}
	}
}

// ----------------------------------------------------
// global navigation
// ----------------------------------------------------
.nav-main{
	@if $type == "all"{
		&__list{
			> li{
				font-size: 1.6rem;
				&:not(:last-child){
					margin-bottom: 1em;
				}
				> a{
					display: inline-block;
					text-decoration: none;
					border-bottom: 1px solid transparent;
					// &:before{
					// 	content: '\e90f';
					// 	font-family: 'webicon';
					// 	display: inline-block;
					// 	margin-right: .5em;
					// }
					&:hover{
						border-bottom-color: $color_accent;
					}
					i{
						display: inline-block;
					}
					.icon-camera{}
					.icon-arrow-right{
						font-size: 1.2rem;
						width: 20px;
					}
				}
			}
		}
	}
	@if $type == "tab"{
		&__list{
			> li{
				font-size: 1.5rem;
			}
		}
	}
	@if $type == "sp"{
		transition: all .8s;
		transform: translateX(40px);
		padding: 16px;
		&__list{
			> li{
				font-size: 1.4rem;
			}
		}
	}
}
.nav-main-sub{
	@if $type == "all"{
		padding: 0 0 0 24px;
		> li{
			font-size: 1.4rem;
		}
	}
	@if $type == "tab"{
		> li{
			font-size: 1.3rem;
		}
	}
}


// ----------------------------------------------------
// contents layout
// ----------------------------------------------------
.page-content {
	@if $type == "all"{
		display: flex;
		flex-direction: row-reverse;
	}
	@if $type == "sp"{
		display: block;
	}
}
.content-main{
	@if $type == "all"{
		width: 630px;
	}
	@if $type == "sp"{
		width: auto;
	}
}
.content-sub{
	@if $type == "all"{
		width: 270px;
		padding: 0 24px 0 0;
	}
	@if $type == "sp"{
		transition: all .8s;
		width: auto;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100%;
		height: 100vh;
		padding-top: $sp_header_height;
		background-color: rgba(#fff, .9);
		overflow: auto;
		visibility: hidden;
		opacity: 0;
		&.is-open{
			visibility: visible;
			opacity: 1;
			.upper-nav__list,
			.nav-main{
				transform: translateX(0);
			}
		}
	}
}

// ----------------------------------------------------
// main block
// ----------------------------------------------------
.breadcrumbs{
	@if $type == "all"{
		@extend %oneline;
		margin: 0 0 16px;
		padding: 4px;
		background-color: #f9f9f9;
		text-align: right;
		font-size: 1.2rem;
		> li{
			display: inline-block;
			&:not(:last-child){
				&:after{
					content: '>';
					margin: 0 .5em;
				}
			}
		}
	}
}

.index-list{
	@if $type == "all"{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -15px;
		> li{
			width: 33.33%;
			padding: 15px;
			a{
				height: 100%;
				display: block;
				border: 1px solid #808080;
				background-color: #808080;
				color: #fff;
				text-decoration: none;
				.txt{
					display: flex;
					align-items: center;
					padding: .5em;
				}
				&:hover{
					background-color: $color_font;
				}
			}
		}
	}
	@if $type == "tab"{
		margin: 0 -10px;
		> li{
			padding: 10px;
		}
	}
	@if $type == "sp"{
		margin: 0 -10px;
		> li{
			width: 50%;
			padding: 10px;
			img{
				width: 100%;
			}
			a{
				font-size: 1.3rem;
			}
		}
	}
}


.title-lg{
	@if $type == "all"{
		display: flex;
		align-items: center;
		font-size: 2.4rem;
		letter-spacing: .1em;
		&:after{
			content: '';
			flex-grow: 1;
			margin-left: 24px;
			border-top: 1px solid $color_line;
		}
	}
	@if $type == "sp"{
		font-size: 2rem;
	}
}
.title-md{
	@if $type == "all"{
		padding-bottom: .2em;
		border-bottom: 2px solid $color_font;
		box-shadow: inset 0 -1px 0 0 #fff,
		            inset 0 -2px 0 0 $color_font;
		font: bold 2.4rem/1.2 $font_mincho;
	}
}
.title-sm{
	@if $type == "all"{
		padding: .5em .5em .3em;
		background-color: #dcdcdc;
		font-size: 1.8rem;

	}
}

.p{
	@if $type == "all"{
		line-height: 1.8;
	}
}

.gallery{
	@if $type == "all"{
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin: 0 -15px;
		> li{
			width: 33.33%;
			padding: 15px;
			text-align: center;
			img{
				padding: 2px;
				border: 1px solid #eaeaea;
			}
		}
	}
	@if $type == "sp"{
		margin: 0 -8px;
		> li{
			width: 50%;
			padding: 8px;
		}
	}
}

.gallery-mini{
	@if $type == "all"{
		display: flex;
		flex-wrap: wrap;
		margin: 0 -7px;
		> li{
			width: 16.66%;
			padding: 7px;
			img{
				padding: 4px;
				border: 1px solid #eaeaea;
			}
		}
	}
	@if $type == "tab"{
		> li{
			width: 20%;
		}
	}
	@if $type == "sp"{
		> li{
			width: 33.33%;
		}
	}
}
// ----------------------------------------------------
// sub block
// ----------------------------------------------------


// ----------------------------------------------------
// footer
// ----------------------------------------------------
.page-footer{
	@if $type == "all"{
		margin: 80px 0 0;
	}
	@if $type == "sp"{
		margin: 40px 0 0;
	}
}
.footer-nav{
	@if $type == "all"{
		display: flex;
		justify-content: flex-end;
		> li{
			&:not(:last-child){
				&:after{
					content: '|';
					margin: 0 1em;
				}
			}
		}
	}
	@if $type == "sp"{
		justify-content: center;
		flex-wrap: wrap;
		> li{
			font-size: 1.1rem;
			width: 33.33%;
			padding: 1px;
			letter-spacing: 0;
			a{
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				padding: 4px;
				background-color: #eee;
				text-align: center;
			}
			&:not(:last-child){
				&:after{
					content: none;
				}
			}
		}
	}
}
.pageup{
	@if $type == "all"{
		position: fixed;
		right: 1em;
		bottom: 16px;
		transition: transform .8s;
		transform: translateY(200%);
		&.is-show{
			transform: translate(0);
		}
		a{
			display: flex;
			justify-content: center;
			align-items: center;
			width: 42px;
			height: 42px;
			border-radius: 50%;
			background-color: $color_font;
			color: #fff;
			text-decoration: none;
			&:hover{
				background-color: #333;
				// color: $color_font;
			}
		}
	}
	@if $type == "sp" or $type == "tab"{
		position: relative;
		right: auto;
		bottom: -32px;
		transform: none;
		// text-align: center;
		font-size: 1.2rem;
		a{
			width: 36px;
			height: 36px;
			margin: 0 auto;
			// display: inline-block;
		}
	}
}

.about-info{
	@if $type == "all"{
		display: flex;
		margin: 16px -30px 0;
		padding: 24px 30px 0;
		border-top: 1px solid #e5e5e5;
		&__address{
			flex-shrink: 0;
			padding: 0 32px 0 0;
			.text{
				margin: 16px 0 0;
				line-height: 1.6;
				font-size: 1.3rem;
				> dd{
					font-size: 1.2rem;
				}
			}
		}
		&__summary{
			dl{
				margin: 0 0 24px;
				dt{
					padding: 0 0 4px;
					border-bottom: 1px solid $color_line;
				}
				dd{
					margin: 16px 0 0;
					font-size: 1.2rem;
					letter-spacing: 0;
				}
			}
		}
	}
	@if $type == "sp"{
		display: block;
		margin: 16px 0 0;
		padding: 32px 0 0;
		&__address{
			display: flex;
			align-items: center;
			justify-content: center;
			.image{
				padding: 0 16px 0 0;
				width: 50%;
			}
			.text{
				margin: 0;
				> dt{
					font-size: 1.2rem;
					font-weight: bold;
				}
				> dd{
					font-size: 1rem;
				}
			}
		}
		&__summary{
			dl{
				margin: 24px 16px;
				dt{
					padding: 0 0 4px;
				}
				dd{
					margin: 8px 0 0;
					font-size: 1rem;
				}
			}
		}
	}
}

.footer-bottom{
	@if $type == "all"{
		display: flex;
		flex-direction: row-reverse;
		justify-content: space-between;
		align-items: center;
		padding: 24px 0;
		border-top: 1px solid #e5e5e5;
	}
	@if $type == "sp"{
		display: block;
		padding: 16px 0;
		text-align: center;
		.p-mark{
			img{
				width: 40px;
			}
		}
	}

}
.copyright{
	@if $type == "all"{
		font-size: 1.2rem;
		color: #999;
		small{
			display: block;
			font-size: 1.2rem;
			color: $color_font;
		}
	}
	@if $type == "sp"{
		margin: 16px 0 0;
		font-size: 1rem;
		small{
			padding: 0 0 8px;
			font-size: 1rem;
		}
	}
}
.page-slider{
	@if $type == "all"{
		img{
			width: 60%;
			margin: 0 auto;
		}
	}
	@if $type == "sp"{
		img{
			width: 80%;
		}
	}
}
.bx{
	@if $type == "all"{
		&-prev{}
		&-next{}
	}
	@if $type == "tab"{
		&-prev{
			left: 0;
		}
		&-next{
			right: 0
		}
	}
	@if $type == "sp"{
		&-prev{
			display: none;
		}
		&-next{
			display: none;
		}
	}
}

@import "top";
@import "list";
@import "../parts/table";
@import "../parts/various";


@import "wp";