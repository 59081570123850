
// ===================================================================
// font-size
// ===================================================================
$font_size_list: 62%, 70%, 77%, 85%, 93%, 100%, 108%, 116%, 124%, 131%, 139%, 147%, 154%, 162%, 170%, 177%, 185%, 193%, 200%, 208%, 216%, 224%, 231%, 239%, 247%, 254%, 262%, 270%, 277%, 285%, 293%, 300%, 308%;

@function fz($size) {
	@if $size < 8 {
		$size: 8;
	}
	@if $size > 40 {
		$size: 40;
	}
	@return nth($font_size_list, $size - 7);
}

@function usuku($color, $p:10%) {
	@return desaturate(lighten($color, $p), $p + 10);
}

@function koku($color, $p:10%) {
	@return desaturate(darken($color, $p), $p);
}
