

.bg-gray{
	@if $type == "all"{
		background-color: #dcdcdc;
	}
}

.dl-sbs{
	@if $type == "all"{
		display: flex;
		> dt{

		}
	}
}

.sitemap{
	@if $type == "all"{
		&__list{
			> li{
				display: inline-block;
				margin: 1em 1em 0 0;
				&:before{
					content: '';
					position: relative;
					top: -.1em;
					display: inline-block;
					border: 3px solid transparent;
					border-left-color: $color_accent;
					vertical-align: middle;
					margin-right: .4em;
				}
			}
		}
		.title-md{
			font-size: 1.8rem;
		}
	}
}