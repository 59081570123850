.news{
	@if $type == "all"{
		margin: 40px 0 0;
		&__list{
			margin: 24px 0 0;
		}
		&__link{
			margin: 24px 0 0;
			text-align: right;
			a{
				display: inline-block;
				padding: .8em 2em;
				border-radius: 4px;
				background-color: #d3d3d3;
				font-size: 1.6rem;
				text-decoration: none;
				&:hover{
					background-color: koku(#d3d3d3);
				}
			}
		}
	}
	@if $type == "sp"{
		&__link{
			text-align: center;
			a{
				font-size: 1.4rem;
			}
		}
	}
}
.news-item{
	@if $type == "all"{
		display: flex;
		align-items: flex-start;
		&:not(:last-child){
			margin-bottom: .5em;
		}
		&__date{
			flex-shrink: 0;
		}
		&__category{
			flex-shrink: 0;
			display: inline-block;
			min-width: 5em;
			margin: 0 1em;
			padding: .2em .5em;
			text-align: center;
			background-color: #d3d3d3;
			font-size: 1.2rem;
			line-height: 1.2;
		}
		&__title{
			font-weight: normal;
		}
	}
	@if $type == "sp"{
		flex-wrap: wrap;
		&:not(:last-child){
			margin-bottom: .8em;
		}
		&__title{
			width: 100%;
			margin: 4px 0 0;
			font-size: 1.3rem;
		}
	}
}

.event{
	@if $type == "all"{
		margin: 40px 0 0;
	}
}
.event__list{
	@if $type == "all"{
		margin: 40px 0 0;
	}
}

.event-info{
	@if $type == "all"{
		display: flex;
		&:not(:last-child){
			margin-bottom: .5em;
		}
		> dt{
			width: 11em;
			flex-shrink: 0;
		}
		> dd{}
	}
	@if $type == "sp"{
		display: block;
		&:not(:last-child){
			border-bottom: 1px dotted $color_line;
			padding-bottom: .5em;
			margin-bottom: .5em;
		}
		> dt{
			font-weight: bold;
		}
		> dd{
			font-size: 1.3rem;
		}
	}
}

.pickup{
	@if $type == "all"{
		margin: 40px 0 0;
		&__list{}
	}
}
.pickup-item{
	@if $type == "all"{
		display: flex;
		align-items: center;
		margin: 24px 0 0;
		&__image{
			flex-shrink: 0;
			width: 80px;
			margin: 0 24px 0 0;
		}
		&__body{
			.title{}
			.date{
				text-align: right;
				display: block;
			}
			.summary{
				margin: 8px 0 0;
				font-size: 1.4rem;
				line-height: 1.4;
			}
		}
	}
	@if $type == "sp"{
		&__image{
			width: 64px;
			margin: 0 16px 0 0;
		}
		&__body{
			.title{
				font-size: 1.4rem;
			}
			.summary{
				font-size: 1.2rem;
			}
		}
	}
}

.links{
	@if $type == "all"{
		margin: 40px 0 0;
		&__list{
			display: flex;
			align-items: center;
			> li{
				&:not(:last-child){
					margin-right: 40px;
				}
				a{
					color: #747577;
					text-decoration: none;
					&:hover{
						color: $color_main;
					}
				}
				i{
					font-size: 3rem;
				}
			}
		}
	}
}